export const environment = {
  production: false,
  env: 'dev',
  oktaConfig: {
    clientId: '0oa1hy8q4q1oPfZQJ0h8',
    issuer: 'https://ssodev.wbd.com/oauth2/default',
    redirectUri: 'https://authz.dev-vod.h264.io/auth-callback',
    scopes: ['openid', 'profile', 'email', 'AuthZgroups'],
    pkce: true,
    logoutRedirectUri: 'https://authz.dev-vod.h264.io',
  },
  apiBaseUrl: '/authz/v1/',
}
